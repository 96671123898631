import React from 'react';

import { withFirebase } from '../../utils/Firebase';
import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';
import Loading from '../Loading';

import style from './PaymentSettings.scss';

const PaymentSettings = ({ firebase, authUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [portalIsLoading, setPortalIsLoading] = React.useState(false);
  const [pricingIsLoading, setPricingIsLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [currentSub, setCurrentSub] = React.useState({});

  const getCustomerSubscriptions = async () => {
    await firebase.firestore
      .collection('customers')
      .doc(authUser.uid)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          // Show products
          console.log('no subs');
        } else {
          setCurrentSub(snapshot.docs[0].data().product.id);
        }
      });
  };

  const updatePricingPlans = async () => {
    setPricingIsLoading(true);
    const wmpProducts = {};
    const productsSnap = await firebase.firestore
      .collection('products')
      .where('active', '==', true)
      .get();

    const foreachPromises = productsSnap.docs.map(async (productDoc) => {
      const priceSnap = await productDoc.ref
        .collection('prices')
        .where('active', '==', true)
        .orderBy('unit_amount')
        .get();

      const wmpPricingPlans = [];

      priceSnap.docs.forEach((priceDoc) => {
        wmpPricingPlans.push({
          id: priceDoc.id,
          ...priceDoc.data(),
        });
      });

      wmpProducts[productDoc.id] = { prices: wmpPricingPlans, ...productDoc.data() };
    });

    await Promise.all(foreachPromises);

    console.log('products', wmpProducts);
    setProducts(wmpProducts); //?.sort((a, b) => (a.price > b.price ? 1 : -1)));
    setPricingIsLoading(false);
  };

  React.useEffect(() => {
    if (firebase) {
      updatePricingPlans();
      getCustomerSubscriptions();
    }
  }, [firebase]);

  const subscribeToPrice = async (priceId) => {
    setIsLoading(true);

    // Call billing portal function
    const docRef = await firebase.firestore
      .collection('customers')
      .doc(authUser.uid)
      .collection('checkout_sessions')
      .add({
        mode: 'subscription',
        price: priceId, // One-time price created in Stripe
        success_url: window.location.href,
        cancel_url: window.location.href,
      });

    docRef.onSnapshot((snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };

  const redirectToCustomerPortal = async () => {
    // setIsLoading(true);
    //load customer portal
    const functionRef = firebase.functions.httpsCallable(
      'ext-firestore-stripe-payments-createPortalLink'
    );
    const { data } = await functionRef({ returnUrl: window.location.href });

    window.location = data.url;
  };

  return (
    <div className={cn('row', style.subs)}>
      <div className="col-12">
        <h1>
          Payment settings{''}
          <br /> <br />
          <Button isLoading={portalIsLoading} onClick={redirectToCustomerPortal}>Stripe Customer portal</Button>
        </h1>
        {currentSub && products[currentSub] && (
          <>
            <h2>Current subscription: </h2>
            <p className={style.currentSub}>{products[currentSub].name}</p>
          </>
        )}
        <h2>Choose your plan:</h2>
      </div>
      {!pricingIsLoading ? (
        Object.entries(products).map(([productId, product]) => (
          <div key={productId} className="col-12 col-md-6 col-xl-3">
            <div className={style.sub}>
              <p>{product.name}</p>
              {product.prices &&
                product.prices.map((price, index) => (
                  <div key={price.id} className="price">
                    <p>
                      {`${new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: price.currency,
                      }).format((price.unit_amount / 100).toFixed(2))} per ${
                        price.interval ?? 'once'
                      }`}
                    </p>
                    {currentSub === productId ? (
                      <Button isDisabled>
                        Subscribed
                      </Button>
                    ) : (
                      <Button onClick={(e) => subscribeToPrice(price.id)} isLoading={isLoading}>
                        Subscribe
                      </Button>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default withFirebase(PaymentSettings);
